import {FC, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {NavLink, useLocation} from "react-router-dom";
import {GiPayMoney} from 'react-icons/gi'

import Logo from '../../../assets/img/admin-logo.svg'
import MiniLogo from '../../../assets/img/mini-admin-logo.svg'
import PaymentIcon from "../icons/PaymentIcon.tsx";
import AccountIcon from "../icons/AccountIcon.tsx";
import BalanceIcon from "../icons/BalanceIcon.tsx";
import ApiReferenceIcon from "../icons/ApiReferenceIcon.tsx";
import {SidebarType} from "../../types/components/sidebar.type.ts";

const Sidebar: FC<SidebarType> = ({sidebarOpen, setSidebarOpen}) => {
    const location = useLocation();
    const {pathname} = location;
    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', String(sidebarExpanded));
        if (sidebarExpanded) {
            document.querySelector('body')?.classList.add('sidebar-expanded');
        } else {
            document.querySelector('body')?.classList.remove('sidebar-expanded');
        }
    }, [sidebarExpanded]);


    return (
        <div>
            {/* Sidebar backdrop (mobile only) */}
            <div
                className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
                    sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
                onClick={() => setSidebarOpen(!sidebarOpen)}
                aria-hidden="true"
            ></div>

            {/* Sidebar */}
            <div
                id="sidebar"
                className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
                    sidebarOpen ? 'translate-x-0' : '-translate-x-64'
                }`}
            >
                {/* Sidebar header */}
                <div className="flex justify-between mb-10 pr-3 sm:px-2">
                    {/* Close button */}
                    <button
                        className="lg:hidden text-slate-500 hover:text-slate-400"
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                    >
                        <span className="sr-only">Close sidebar</span>
                        <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"/>
                        </svg>
                    </button>
                    {/* Logo */}
                    <NavLink end to="/" className="block" onClick={() => setSidebarOpen(!sidebarOpen)}>
                        {sidebarExpanded
                            ? <img className="w-[90px] h-[30px]" src={Logo} alt="Logo"/>
                            : <img src={MiniLogo} alt="Logo"/>
                        }
                    </NavLink>
                </div>

                {/* Links */}
                <div className="space-y-8">
                    {/* Pages group */}
                    <div>
                        <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
                            <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                                  aria-hidden="true">
                            •••
                            </span>
                            <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">Pages</span>
                        </h3>
                        <ul className="mt-3">
                            {/* Payments */}
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === '/' && 'bg-slate-900'}`}>
                                <NavLink
                                    end
                                    to="/"
                                    className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                        pathname === '/' && 'hover:text-slate-200'
                                    }`}
                                    onClick={() => setSidebarOpen(!sidebarOpen)}
                                >
                                    <div className="flex items-center">
                                        <PaymentIcon className="shrink-0" isActive={pathname === '/'}/>
                                        <span
                                            className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Payments
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            {/* Payouts */}
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === '/payouts' && 'bg-slate-900'}`}>
                                <NavLink
                                    end
                                    to="/payouts"
                                    className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                        pathname === '/payouts' && 'hover:text-slate-200'
                                    }`}
                                    onClick={() => setSidebarOpen(!sidebarOpen)}
                                >
                                    <div className="flex items-center">
                                        <GiPayMoney className={`shrink-0 ${pathname === '/payouts' ? 'text-[#A5B4FC]' : 'text-[#94A3B8]'}`} size={24}/>
                                        <span
                                            className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Payouts
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            {/* Account */}
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('account') && 'bg-slate-900'}`}>
                                <NavLink
                                    end
                                    to="/account"
                                    className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                        pathname.includes('account') && 'hover:text-slate-200'
                                    }`}
                                    onClick={() => setSidebarOpen(!sidebarOpen)}
                                >
                                    <div className="flex items-center">
                                        <AccountIcon className="shrink-0" isActive={pathname.includes('account')}/>
                                        <span
                                            className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Account
                                        </span>
                                    </div>
                                </NavLink>
                            </li>

                            {/* Balance */}
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('balance') && 'bg-slate-900'}`}>
                                <NavLink
                                    end
                                    to="/balance"
                                    className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                        pathname.includes('balance') && 'hover:text-slate-200'
                                    }`}
                                    onClick={() => setSidebarOpen(!sidebarOpen)}
                                >
                                    <div className="flex items-center">
                                        <BalanceIcon className="shrink-0" isActive={pathname.includes('balance')}/>
                                        <span
                                            className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Balance
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            {/*<li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('payment-addresses') && 'bg-slate-900'}`}>*/}
                            {/*    <NavLink*/}
                            {/*        end*/}
                            {/*        to="/payment-addresses"*/}
                            {/*        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${*/}
                            {/*            pathname.includes('payment-addresses') && 'hover:text-slate-200'*/}
                            {/*        }`}*/}
                            {/*        onClick={() => setSidebarOpen(!sidebarOpen)}*/}
                            {/*    >*/}
                            {/*        <div className="flex items-center">*/}
                            {/*            <MdOutlinePayments size={25}*/}
                            {/*                               className={`${pathname.includes('temp-addresses') ? 'text-indigo-500' : ''} shrink-0 opacity-80`}/>*/}
                            {/*            <span*/}
                            {/*                className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">*/}
                            {/*                Pay Addresses*/}
                            {/*            </span>*/}
                            {/*        </div>*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}
                            {/* API Reference */}
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('api-reference') && 'bg-slate-900'}`}>
                                <a
                                    href="https://my.dev.usdtpayments.com/doc"
                                    className='block text-slate-200 hover:text-white truncate transition duration-150'
                                    target='_blank'
                                >
                                    <div className="flex items-center">
                                        <ApiReferenceIcon className="shrink-0"
                                                          isActive={pathname.includes('api-reference')}/>
                                        <span
                                            className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            API Reference
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Expand / collapse button */}
                <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
                    <div className="px-3 py-2">
                        <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                            <span className="sr-only">Expand / collapse sidebar</span>
                            <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                                <path className="text-slate-400"
                                      d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"/>
                                <path className="text-slate-600" d="M3 23H1V1h2z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

};

Sidebar.propTypes = {
    sidebarOpen: PropTypes.bool.isRequired,
    setSidebarOpen: PropTypes.func.isRequired,
};

export default Sidebar;
