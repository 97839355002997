import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import AuthLayout from "./layouts/auth.layout.tsx";
import SignInPage from "./pages/auth/sign-in.page.tsx";
import SignUpPage from "./pages/auth/sign-up.page.tsx";
import AdminLayout from "./layouts/admin.layout.tsx";
import PaymentsPage from "./pages/admin/payments.page.tsx";
import PaymentPage from "./pages/public/payment.page.tsx";
import AccountPage from "./pages/admin/account.page.tsx";
import BalancePage from "./pages/admin/balance.page.tsx";
import ProfileLayout from "./layouts/profile.layout.tsx";
import EmptyPage from "./pages/admin/profile/empty.page.tsx";
import PersonalPage from "./pages/admin/profile/personal.page.tsx";
import PayoutsPage from "./pages/admin/payouts.page.tsx";
import PayoutAddressesPage from "./pages/admin/profile/payout-addresses.page.tsx";
import SecurityPage from "./pages/admin/profile/security.page.tsx";
import NotificationPage from "./pages/admin/profile/notification.page.tsx";
import NetworksPage from "./pages/admin/networks.page.tsx";


const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/auth/*"
                    element={
                        <AuthLayout>
                            <Routes>
                                <Route path="/sign-in" element={<SignInPage />} />
                                <Route path="/sign-up" element={<SignUpPage />} />
                                <Route path="/*" element={ <Navigate to="/auth/sign-in" /> } />
                            </Routes>
                        </AuthLayout>
                    }
                />

                <Route
                    path="/*"
                    element={
                        <AdminLayout>
                            <Routes>
                                <Route path="/" element={<PaymentsPage />} />
                                <Route path="/payouts" element={<PayoutsPage />} />
                                <Route path="/account" element={<AccountPage />} />
                                <Route path="/balance" element={<BalancePage />} />
                                {/*<Route path="/payment-addresses" element={<TempAddressesPage />} />*/}
                                <Route path="/networks" element={<NetworksPage />} />
                                <Route path="/profile/*" element={
                                    <ProfileLayout>
                                        <Routes>
                                            <Route path="/" element={<EmptyPage />} />
                                            <Route path="/personal" element={<PersonalPage />} />
                                            <Route path="/payout-whitelist" element={<PayoutAddressesPage />} />
                                            <Route path="/notification" element={<NotificationPage />} />
                                            <Route path="/security" element={<SecurityPage />} />
                                        </Routes>
                                    </ProfileLayout>
                                } />
                                {/*<Route path="/*" element={ <Navigate to="/error/not-found" /> } />*/}
                            </Routes>
                        </AdminLayout>
                    }
                />

                <Route path="/i/:internalId" element={<PaymentPage />} />
                {/*<Route path="/error/not-found" element={<NotFoundPage />} />*/}
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
