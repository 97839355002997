import { FC } from "react";
import { QRCode } from "react-qrcode-logo";
import {
	formatCurrency,
	getDescriptionByCurrency,
	getNetworkIcon,
} from "../../../utils/currency.util.ts";
import { toShort2 } from "../../../utils/common.util.ts";
import { PaymentPublicBlockType } from "../../../types/payment/payment-public-block.type.ts";
import TimerComponent from "../../general/timer.component.tsx";
import { useCopy } from "../../../hooks/copy.hook.ts";

const PaymentNew: FC<PaymentPublicBlockType> = ({ payment }) => {
	const [isCopied, copy] = useCopy(1000);

	return (
		<div className="bg-white px-8 pb-5 rounded-[10px] shadow-lg border">
			<div className="text-center mb-4 pt-7">
				<h1 className="text-[36px] text-gray-800 font-bold">
					{formatCurrency(payment?.amount || "0")}
				</h1>
				<div className="text-[20px] text-gray-500 font-bold mb-2 flex items-center justify-center gap-2">
					<div className="inline-flex w-6 h-6 rounded-full shrink-0 fill-current">
						<img
							className=" rounded-full"
							src={getNetworkIcon(payment?.walletType)}
							alt={payment?.walletType}
						/>
					</div>
					USDT
				</div>
				<div className="text-[14px] text-gray-400">{payment?.description}</div>
			</div>

			<div className="flex flex-col items-center justify-center mb-6">
				<div className="relative mb-4">
					<QRCode
						ecLevel="L"
						eyeRadius={[10, 10, 10]}
						fgColor="#223049"
						value={payment?.address}
						size={210}
						// removeQrCodeBehindLogo={true}
						// logoImage={getNetworkIcon(payment?.walletType)}
						// logoWidth={45}
						// logoHeight={45}
						// logoPaddingStyle="circle"
						// quietZone={0}
					/>
				</div>
				<div className="relative mb-1">
					<input
						id="form-search"
						className={`form-input w-full pr-9 text-center ${isCopied ? "text-green-500" : "text-gray-900"}`}
						value={isCopied ? "Copied" : toShort2(payment?.address, 7, 7)}
						disabled
					/>
					<button
						onClick={() => copy(payment?.address || "")}
						className="absolute inset-0 left-auto group"
						type="submit"
						aria-label="Search"
					>
						<svg
							className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-3"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M2.99999 0.916626C2.44746 0.916626 1.91755 1.13612 1.52685 1.52682C1.13615 1.91752 0.916656 2.44743 0.916656 2.99996V8.33329C0.916656 8.88583 1.13615 9.41573 1.52685 9.80643C1.91755 10.1971 2.44746 10.4166 2.99999 10.4166H3.58332V11C3.58332 12.1506 4.51606 13.0833 5.66666 13.0833H11C12.1506 13.0833 13.0833 12.1506 13.0833 11V5.66663C13.0833 4.51603 12.1506 3.58329 11 3.58329H10.4167V2.99996C10.4167 2.44742 10.1972 1.91752 9.80646 1.52682C9.41576 1.13612 8.88586 0.916626 8.33332 0.916626H2.99999ZM8.91666 3.58329V2.99996C8.91666 2.84525 8.8552 2.69688 8.7458 2.58748C8.63641 2.47808 8.48803 2.41663 8.33332 2.41663H2.99999C2.84528 2.41663 2.69691 2.47808 2.58751 2.58748C2.47811 2.69688 2.41666 2.84525 2.41666 2.99996V8.33329C2.41666 8.488 2.47811 8.63638 2.58751 8.74577C2.69691 8.85517 2.84528 8.91663 2.99999 8.91663H3.58332V5.66663C3.58332 4.51603 4.51606 3.58329 5.66666 3.58329H8.91666ZM5.08332 9.66663V11C5.08332 11.3221 5.34449 11.5833 5.66666 11.5833H11C11.3222 11.5833 11.5833 11.3221 11.5833 11V5.66663C11.5833 5.34446 11.3222 5.08329 11 5.08329H9.66666H5.66666C5.34449 5.08329 5.08332 5.34446 5.08332 5.66663V9.66663Z"
								fill="#6366F1"
							/>
						</svg>
					</button>
				</div>
				<div className="text-gray-400 text-xs">
					{getDescriptionByCurrency(payment?.currency)}
				</div>
			</div>

			<div className="text-gray-400 flex items-center justify-center">
				<TimerComponent
					className="text-indigo-700 m-0 mr-1"
					date={payment?.expiredAt}
				/>
				<div>time left to pay the invoice</div>
			</div>
		</div>
	);
};

export default PaymentNew;
