import {useCallback, useEffect, useState} from "react";

export const useCopy = (timer = 3000) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = useCallback(async (text: string) => {
        try {
            if ('clipboard' in navigator) {
                try {
                    await navigator.clipboard.writeText(text);
                    setIsCopied(true);
                    console.log('1')
                    return;
                } catch (err) {
                    console.log('Clipboard API failed, falling back to alternative method');
                }
            }

            const textarea = document.createElement('textarea');
            textarea.value = text;
            textarea.style.position = 'fixed';
            textarea.style.opacity = '0';
            document.body.appendChild(textarea);
            textarea.select();

            try {
                document.execCommand('copy');
                setIsCopied(true);
            } catch (err) {
                console.error('Failed to copy text:', err);
            } finally {
                document.body.removeChild(textarea);
            }
        } catch (err) {
            console.error('Copy failed:', err);
        }
    }, []);

    useEffect(() => {
        let timeout: number | null = null;

        if (isCopied) {
            timeout = setTimeout(() => {
                setIsCopied(false);
            }, timer);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isCopied, timer]);

    return [isCopied, copyToClipboard] as const;
}

// export const useCopy = (timer = 3000) => {
//     const [isCopied, setIsCopied] = useState(false);
//
//     const copyToClipboard = useCallback(async (text: string) => {
//         // @todo add and if has write access
//         if ('clipboard' in navigator) {
//             await navigator.clipboard.writeText(text);
//         } else {
//             document.execCommand('copy', true, text);
//         }
//
//         setIsCopied(true);
//     }, [])
//
//     useEffect(() => {
//         let timeout: number | null = null;
//
//         if (isCopied) {
//             timeout = setTimeout(() => {
//                 setIsCopied(false);
//             }, timer);
//         }
//
//         return () => {
//             if (timeout) {
//                 clearTimeout(timeout);
//             }
//         };
//     }, [isCopied, timer]);
//
//     return [isCopied, copyToClipboard] as const;
// }
