import {FC, useEffect, useState} from "react";
import {AdminLayoutType} from "../common/types/layouts/admin-layout.type.ts";
import Sidebar from "../common/components/general/sidebar.component.tsx";
import Header from "../common/components/general/header.component.tsx";
import {AppDispatch} from "../store";
import {useDispatch, useSelector} from "react-redux";
import {QueryResult, SubscriptionResult, useQuery, useSubscription} from "@apollo/client";
import {
    initialProfileState,
    initialWalletState,
    setProfile,
    setWallet,
    updateBalanceByType,
    userSelector
} from "../store/user.store.ts";
import {PROFILE_QUERY} from "../api/graphql/queries/profile.query.ts";
import {GetProfileResponseType} from "../common/types/user/get-profile-response.type.ts";
import {ProfileStateType, UserStateType, WalletStateType} from "../common/types/store/user-state.type.ts";
import {GetWalletResponseType} from "../common/types/user/get-wallet-response.type.ts";
import {WALLET_QUERY} from "../api/graphql/queries/wallet.query.ts";
import {Slide, toast, ToastContainer} from "react-toastify";
import {authSelector} from "../store/auth.store.ts";
import {AuthStateType} from "../common/types/store/auth-state.type.ts";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {USER_NOTIFICATION} from "../api/graphql/subscriptions/user.subscription.ts";
import NotificationToast from "../common/components/toasts/notification.toast.tsx";
import {UserNotificationResponse} from "../common/types/notification/user-notification-response.type.ts";
import {UserNotification} from "../common/types/notification/user-notification.type.ts";
import {addPayment, setErrorToPaymentPanel, setStatus, setSysStatus, setUserStatus} from "../store/payment.store.ts";
import {NotificationName} from "../common/enums/notification-name.enum.ts";
import {NotificationType} from "../common/enums/notification.enum.ts";
import ApprovePayoutAddressModal from "../common/components/modals/approve-payout-address.modal.tsx";
import {addPayout, setPayoutError, updatePayoutAddressStatus, setStatus as setPayoutStatus} from "../store/payout.store.ts";
import {addPayoutAddress, setStatus as setPayoutAddressStatus} from "../store/payout-addresses.store";
import DeletePayoutAddressModal from "../common/components/modals/delete-payout-address.modal.tsx";
import {OperationType} from "../common/enums/operation-type.enum.ts";
import {updateBalance} from "../store/temp-address.store.ts";
import SendCallbackModal from "../common/components/modals/send-callback.modal.tsx";
import {addBalance} from "../store/balance.store.ts";


const AdminLayout: FC<AdminLayoutType> = ({children}) => {
    const dispatcher: AppDispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();

    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

    const {isAuth}: AuthStateType = useSelector(authSelector);
    const {profile: {id}}: UserStateType = useSelector(userSelector);

    // useEffect(() => {
    //     if (email) {
    //         console.log(email)
    //         window.$or.setUserID(email);
    //         window.$or.reloadFlags();
    //
    //         console.log(window.$or.getAllFeatureFlags())
    //
    //     }
    // }, [email]);

    const getProfile: QueryResult<GetProfileResponseType> = useQuery<GetProfileResponseType>(PROFILE_QUERY);
    const getWallet: QueryResult<GetWalletResponseType> = useQuery<GetWalletResponseType>(WALLET_QUERY);

    const userNotificationSub: SubscriptionResult<UserNotificationResponse> = useSubscription<UserNotificationResponse>(
        USER_NOTIFICATION, {
            shouldResubscribe: true,
            variables: {
                userId: id
            },
            skip: !isAuth || id === 0,
            fetchPolicy: 'no-cache'
        }
    );

    useEffect((): void => {
        if (!isAuth) {
            navigate('/auth/sign-in')
        }
    }, [isAuth, navigate]);

    // TODO: вынести в notification service
    useEffect(() => {
        if (userNotificationSub.data !== undefined) {
            const response: UserNotification = userNotificationSub.data.userNotification;

            switch (response.name) {
                case NotificationName.USER:
                    if (response.type && response.title && response.message) {
                        toast(<NotificationToast type={response.type} title={response.title}
                                                 message={response.message}/>, {
                            toastId: 'user-notification-' + response.type + '-' + response.message
                        });
                    }
                    break;

                case NotificationName.OPERATION_STATUS:
                    if (response.operationId && response.status) {
                        dispatcher(setStatus({
                            id: response.operationId,
                            status: response.status
                        }));
                    }
                    break;

                case NotificationName.OPERATION_SYS_STATUS:
                    if (response.operationId && response.sysStatus) {
                        dispatcher(setSysStatus({
                            id: response.operationId,
                            sysStatus: response.sysStatus
                        }));
                    }
                    break;

                case NotificationName.OPERATION_USER_STATUS:
                    if (response.operationId && response.userStatus) {
                        dispatcher(setUserStatus({
                            id: response.operationId,
                            userStatus: response.userStatus
                        }));
                    }
                    break;

                case NotificationName.UPDATE_ADMIN_BALANCE:
                    if (response.walletType && response.balance && response.currency) {
                        dispatcher(updateBalanceByType({
                            type: response.walletType,
                            balance: response.balance,
                            currency: response.currency
                        }));
                    }
                    break;
                case NotificationName.OPERATION_ERROR:
                    if (response.operationId && response.error && response.operationType) {
                        switch (response.operationType) {
                            case OperationType.PAYMENT:
                                dispatcher(setErrorToPaymentPanel({
                                    operationId: response.operationId,
                                    error: response.error
                                }));
                                break;
                            case OperationType.PAYOUT:
                                dispatcher(setPayoutError({
                                    operationId: response.operationId,
                                    error: response.error
                                }))
                                break;
                        }

                    }
                    break;
                case NotificationName.PAYOUT_ADDRESS_STATUS:
                    if (response.addressId && response.payoutAddressStatus) {
                        dispatcher(updatePayoutAddressStatus({
                            addressId: response.addressId,
                            status: response.payoutAddressStatus
                        }));
                        dispatcher(setPayoutAddressStatus({
                            id: response.addressId,
                            status: response.payoutAddressStatus
                        }));
                    }
                    break;
                case NotificationName.PAYOUT_ADDRESS_CREATED:
                    if (response.address) {
                        dispatcher(addPayoutAddress(response.address));
                    }
                    break;
                case NotificationName.PAYOUT_CREATED:
                    if (response.payout) {
                        dispatcher(addPayout(response.payout));
                    }
                    break;
                case NotificationName.OPERATION_CREATED:
                    if (response.operation) {
                        dispatcher(addPayment(response.operation));
                    }
                    break;
                case NotificationName.UPDATE_TEMP_ADDRESS_BALANCE:
                    if (response.addressId && response.currency && response.balance) {
                        dispatcher(updateBalance({
                            addressId: response.addressId,
                            currency: response.currency,
                            balance: response.balance
                        }));
                    }
                    break;
                case NotificationName.PAYOUT_CHANGED:
                    if (response.operationId && response.operationId) {
                        dispatcher(setPayoutStatus({
                            id: response.operationId,
                            status: response.status
                        }));
                    }
                    break;
                case NotificationName.BALANCE_OPERATION_CREATED:
                    if (response.operation) {
                        dispatcher(addBalance(response.operation));
                    }
                    break;
            }

        }

    }, [dispatcher, userNotificationSub.data]);

    useEffect(() => {
        if (getProfile.data) {
            const response: ProfileStateType = getProfile.data.getProfile
            dispatcher(setProfile(response));
        }
        return () => {
            dispatcher(setProfile(initialProfileState))
        }
    }, [getProfile, dispatcher]);

    useEffect(() => {
        if (getWallet.data) {
            const response: WalletStateType = getWallet.data.getWallet
            dispatcher(setWallet(response));
        }
        return () => {
            dispatcher(setWallet(initialWalletState))
        }
    }, [getWallet, dispatcher]);

    useEffect(() => {
        if (getProfile.error) {
            toast(<NotificationToast type={NotificationType.ERROR} title="Profile"
                                     message={getProfile.error.message}/>, {
                toastId: 'get-profile-request'
            });
        }
    }, [getProfile]);


    useEffect(() => {
        if (getWallet.error) {
            toast(<NotificationToast type={NotificationType.ERROR} title="Wallet" message={getWallet.error.message}/>, {
                toastId: 'get-wallet-request'
            });
        }
    }, [getWallet])

    return (
        <div className="flex h-screen overflow-hidden">
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                {/*  Site header */}
                <Header
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    profileLoading={getProfile.loading}
                    walletLoading={getWallet.loading}
                />
                {children}
            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
                theme="colored"
                closeButton={false}
                icon={false}
                transition={Slide}
                limit={4}
            />
            <ApprovePayoutAddressModal/>
            <DeletePayoutAddressModal/>
            <SendCallbackModal />
        </div>
    );
}

export default AdminLayout
