export const SYS_STATUS_DESCRIPTION = {
  WAIT_PAYMENT: "Waiting for payment",
  IN_CHECK_PAYMENT: "Payment being checked",
  WAIT_FEE: "Waiting for fee",
  IN_ACTIVATE_PAYMENT: "Payment being activated",
  WAIT_SEND: "Waiting for sending",
  IN_SEND_PAYMENT: "Payment being sent",
  WAIT_VALIDATE_FEE: "Activating",
  IN_VALIDATE_FEE: "Validating activation",
  WAIT_VALIDATE: "Sending",
  IN_VALIDATE: "Validating",
  COMPLETED: "Completed",
  UNKNOWN: "unknown",
};
